<template>
  <b-form @submit.prevent="addDomain">
    <b-form-group label="Nom" label-for="name-input">
      <b-form-input
          id="name-input"
          v-model="$v.domain.name.$model"
          type="text"
          required
          placeholder="Renseignez un nom"
          :state="$v.domain.name.$dirty ? !$v.domain.name.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.domain.name.$invalid">
        <span v-if="!$v.domain.name.required">Vous devez renseigner ce champ</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Clé d'API" label-for="api-key-input">
      <b-form-input
          id="api-key-input"
          v-model="$v.domain.apiKey.$model"
          type="text"
          required
          placeholder="Renseignez une clé d'API"
          :state="$v.domain.apiKey.$dirty ? !$v.domain.apiKey.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.domain.apiKey.$invalid">
        <span v-if="!$v.domain.apiKey.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.domain.apiKey.alphaNum">
          La clé d'API doit être une chaine de caractères alphanumérique
        </span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Date de génération" label-for="generation-date-input">
      <b-form-datepicker
          id="generation-date-input"
          v-model="$v.domain.generationDate.$model"
          :state="$v.domain.generationDate.$dirty ? !$v.domain.generationDate.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.domain.generationDate.$invalid">
        <span v-if="!$v.domain.generationDate.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.domain.generationDate.dateISO8601">Vous devez renseigner une date valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Type" label-for="type-input">
      <b-form-select
          id="type-input"
          v-model="$v.domain.type.$model"
          :options="types"
          :state="$v.domain.type.$dirty ? !$v.domain.type.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.domain.type.$invalid">
        <span v-if="!$v.domain.type.validType">Type de domaine invalide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="$v.domain.externalAuth.$model" switch>Authentification externe</b-form-checkbox>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="$v.$invalid">
      Envoyer
    </b-button>
  </b-form>
</template>

<script>
import {boolean, dateISO8601} from "@/validators";
import {alphaNum, required} from "vuelidate/lib/validators";

const DOMAIN_TYPES = [
  {value: "jt", text: "Jalis Touch"},
  {value: "ej", text: "EJalis"},
  {value: "api", text: "API"},
];

export default {
  data() {
    return {
      domain: {
        name: "",
        apiKey: "",
        type: "jt",
        externalAuth: false,
        generationDate: new Date().toISOString().slice(0, 10),
      },
      types: DOMAIN_TYPES,
    };
  },
  validations: {
    domain: {
      name: {
        required,
      },
      apiKey: {
        required,
        alphaNum,
      },
      generationDate: {
        required,
        dateISO8601,
      },
      externalAuth: {
        boolean,
      },
      type: {
        validType: (value) => DOMAIN_TYPES.find((item) => item.value === value),
      },
    },
  },
  methods: {
    async addDomain() {
      try {
        await this.$store.dispatch("domains/addDomain", this.domain);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la création du domaine",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Domaine ajouté",
      });
      await this.$router.push({name: "domains"});
    },
  },
};
</script>

